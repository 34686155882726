const certificatesData = [
  {
    studentId: "UB/DTCS/24B113",
    name: "MANEGHO TOH GEMUH",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCC/24B101",
    name: "ANDY AZINWI TABID TANTE",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCS/24B110",
    name: "KOGGE LOVERT EBONG",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCC/24B109",
    name: "KENYI MBOUMMEU MICHELLE",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCS/24B111",
    name: "MAMBO NICOLAS MEDINY",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCC/24B102",
    name: "ASHU TABI ELVIS",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCS/24B103",
    name: "ATEM ATEMAFAC GEORGE",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCC/24B114",
    name: "ANUMOH MICHAE",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
  {
    studentId: "UB/DTCS/24B108",
    name: "AGBOR ARREY GODWIL",
    issueDate: "November 08, 2024",
    issuer: "University of Buea in Partnership DobreTech",
  },
];

export default certificatesData;
